import { Component } from 'react'
import { Button } from 'dsm-react-lib'
import { staticAssetsPath } from '../configs/constants'
import { appInsights } from '../_services/AppInsights'
import { EventType } from '../utility/types'
import { BrandLogo, MiscWrapper } from '../styles/common'

export class ErrorBoundary extends Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    appInsights.trackEvent({
      name: EventType.ErrorBoundary,
      properties: {
        error: error.message,
        errorInfo
      }
    })
    console.log('error', error)
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <MiscWrapper className="bg-white">
          <BrandLogo href="/">
            <img
              alt="img"
              width={'180px'}
              src={`${staticAssetsPath}/images/logo/Centrali-Portal.svg`}
            />
          </BrandLogo>
          <div className="inner p-sm-3">
            <div className="w-100 text-center">
              <h2 className="mb-1">
                <b>Oh no! 🥺</b>
              </h2>
              <p className="mb-2">{`Something went wrong, please try again later.`}</p>
              <a href="/" className="d-flex justify-content-center">
                <Button label=" Back to home" color="primary" className="btn-sm-block mb-2" />
              </a>
              <div>
                <img
                  className="img-fluid"
                  src={`${staticAssetsPath}/images/something_went_wrong.png`}
                  alt="Something_went_wrong"
                />
              </div>
            </div>
          </div>
        </MiscWrapper>
      )
    }

    // Render children if there's no error
    return this.props.children
  }
}
