/* eslint-disable no-duplicate-case */
import { SET_USERCONTEXT } from '../types/userContextConstants'

const initialState = {
  userContext: {
    name: null,
    accessToken: null,
    id: null,
    roles: []
  }
}

export default function userContextReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERCONTEXT:
      return {
        ...state,
        userContext: action.payload
      }
    default:
      return state
  }
}
