/* eslint-disable no-duplicate-case */
import { ADD_APP, INIT_APP, REMOVE_APP } from '../types/appConstants'

const initialState = {
  apps: [],
  app: {}
}

export default function appsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        apps: action.payload && action.payload.length ? [...action.payload] : state.apps
      }
    case ADD_APP: {
      const uniqueApps = state.apps.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        app: action.payload,
        apps: [...uniqueApps, action.payload]
      }
    }
    case REMOVE_APP:
      return {
        ...state,
        app: action.payload,
        apps: state.apps.filter((item) => item.id !== action.payload.id)
      }
    default:
      return state
  }
}
