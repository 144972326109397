export enum EventType {
  SearchHistory = 'SearchHistory',
  AddToWorkspace = 'AddToWorkspace',
  AddToFavourites = 'AddToFavourites',
  AppInstalled = 'AppInstalled',
  AppLaunched = 'AppLaunched',
  AppRated = 'AppRated',
  ActiveWorkBrowser = 'ActiveWorkBrowser',
  UserGuideAccessed = 'UserGuideAccessed',
  LazyLoadSuccess = 'LazyLoadSuccess',
  LazyLoadFailed = 'LazyLoadFailed',
  JoinEarlyAdopter = 'JoinEarlyAdopter',
  ErrorBoundary = 'ErrorBoundary',
  TopicQueried = 'TopicQueried',
  PortalVisit = 'PortalVisit',
  BannerClick = 'BannerClick',
  ClientAppEvent = 'ClientAppEvent',
  WorkspaceLaunched = 'WorkspaceLaunched',
  WorkspaceAdded = 'WorkspaceAdded',
  WorkspaceRemoved = 'WorkspaceRemoved',
  WorkspaceAppLaunched = 'WorkspaceAppLaunched',
  WorkspaceAppAdded = 'WorkspaceAppAdded',
  WorkspaceAppRemoved = 'WorkspaceAppRemoved',
  WorkspaceActiveApps = 'WorkspaceActiveApps'
}

export enum Role {
  Admin = 'InvDesktop-Admin',
  Contributor = 'InvDesktop-Contributor'
}
