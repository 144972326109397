export const ADD_NOTIFICATION_GROUP = 'ADD_NOTIFICATION_GROUP'
export const REMOVE_NOTIFICATION_GROUP = 'REMOVE_NOTIFICATION_GROUP'
export const GET_NOTIFICATION_GROUP = 'GET_NOTIFICATION_GROUP'
export const GET_NOTIFICATION_GROUPS = 'GET_NOTIFICATION_GROUPS'
export const UPDATE_NOTIFICATION_GROUP_MEMBERS = 'UPDATE_NOTIFICATION_GROUP_MEMBERS'
export const GET_NOTIFICATION_GROUP_MEMBERS = 'GET_NOTIFICATION_GROUP_MEMBERS'
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NEW_NOTIFICATIONS_COUNT = 'GET_NEW_NOTIFICATIONS_COUNT'
export const SET_NOTIFICATION_CONTEXT = 'SET_NOTIFICATION_CONTEXT'
export const ADD_NEW_NOTIFICATION_TO_CONTEXT = 'ADD_NEW_NOTIFICATION_TO_CONTEXT'
export const SET_SCHEDULE_NOTIFICATIONS = 'SET_SCHEDULE_NOTIFICATIONS'
export const ADD_NEW_SCHEDULE_NOTIFICATION = 'ADD_NEW_SCHEDULE_NOTIFICATION'
export const NOTIFICATION_CONTEXT_SET_NEW_COUNT = 'NOTIFICATION_CONTEXT_SET_NEW_COUNT'
