import { ADD_TASK, REMOVE_TASK, UPDATE_TASK } from '../types/smartFlowConstants'

const initialState = {
  tasks: []
}

export default function smartFlowReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TASK: {
      const updatedTask = state.tasks.map((task) => {
        return { ...task, isRunning: false }
      })
      return {
        ...state,
        tasks: [...updatedTask, action.payload]
      }
    }
    case REMOVE_TASK: {
      const filterTask = state.tasks.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        tasks: filterTask
      }
    }
    case UPDATE_TASK: {
      const updatedTask = state.tasks.map((task) => {
        return task.id === action.payload.id ? action.payload : task
      })
      return {
        ...state,
        tasks: updatedTask
      }
    }
    default:
      return state
  }
}
