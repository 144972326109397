/* eslint-disable no-duplicate-case */
import {
  ADD_FAVORITE_APP,
  REMOVE_FAVORITE_APP,
  INIT_FAVORITE_APP
} from '../types/favoriteAppConstants'

const initialState = {
  favoriteApps: [],
  favoriteApp: {}
}

export default function favoriteAppsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_FAVORITE_APP:
      return {
        ...state,
        favoriteApps: action.payload ? action.payload : []
      }
    case ADD_FAVORITE_APP: {
      const uniqueApps = state.favoriteApps.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        favoriteApp: action.payload,
        favoriteApps: [...uniqueApps, action.payload]
      }
    }
    case REMOVE_FAVORITE_APP:
      return {
        ...state,
        favoriteApp: action.payload,
        favoriteApps: state.favoriteApps.filter((item) => item.id !== action.payload.id)
      }
    default:
      return state
  }
}
