/* eslint-disable no-duplicate-case */
import {
  EDIT_WIDGETS,
  REMOVE_WIDGET,
  ADD_WIDGET,
  INIT_WORKSPACE_WIDGETS
} from '../types/widgetConstants'

const initialState = {
  edit: false,
  initWidgets: [],
  activeWidgets: ['News', 'Shortcut', 'Index Monitor', 'Fund Launches', 'Dividend Calendar']
}

export default function appsReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_WIDGETS:
      return {
        ...state,
        edit: action.payload
      }
    case REMOVE_WIDGET:
      return {
        ...state,
        activeWidgets: state.activeWidgets.filter((item) => item !== action.payload)
      }
    case ADD_WIDGET: {
      const uniqueWidgets = state.activeWidgets.filter((item) => item !== action.payload)
      return {
        ...state,
        activeWidgets: [...uniqueWidgets, action.payload]
      }
    }
    case INIT_WORKSPACE_WIDGETS:
      return {
        ...state,
        activeWidgets: [...action.payload]
      }
    default:
      return state
  }
}
