/* eslint-disable no-duplicate-case */
import {
  ADD_CUSTOM_COLLECTION,
  INIT_CUSTOM_COLLECTION,
  REMOVE_CUSTOM_COLLECTION
} from '../types/collectionConstants'

const initialState = {
  customCollections: [],
  customCollection: {}
}

export default function customCollectionsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CUSTOM_COLLECTION:
      return {
        ...state,
        customCollections:
          action.payload && action.payload.length ? [...action.payload] : state.customCollections
      }
    case ADD_CUSTOM_COLLECTION: {
      const uniqueCollections = state.customCollections.filter(
        (item) => item.collectionId !== action.payload.collectionId
      )
      return {
        ...state,
        customCollection: action.payload,
        customCollections: [...uniqueCollections, action.payload]
      }
    }
    case REMOVE_CUSTOM_COLLECTION:
      return {
        ...state,
        customCollection: action.payload,
        customCollections: state.customCollections.filter(
          (item) => item.collectionId !== action.payload.collectionId
        )
      }
    default:
      return state
  }
}
