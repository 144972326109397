/* eslint-disable no-duplicate-case */
import { INIT_SEARCH_APP } from '../types/searchAppsConstants'

const initialState = {
  filters: [],
  searchFilters: []
}

export default function searchAppsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_SEARCH_APP:
      return {
        ...state,
        searchFilters: action.payload,
        filters: []
      }
    default:
      return state
  }
}
