/* eslint-disable no-duplicate-case */
import { ADD_COLLECTION, INIT_COLLECTION, REMOVE_COLLECTION } from '../types/collectionConstants'

const initialState = {
  collections: [],
  collection: {}
}

export default function collectionsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_COLLECTION:
      return {
        ...state,
        collections:
          action.payload && action.payload.length ? [...action.payload] : state.collections
      }
    case ADD_COLLECTION: {
      const uniqueCollections = state.collections.filter(
        (item) => item.collectionId !== action.payload.collectionId
      )
      return {
        ...state,
        collection: action.payload,
        collections: [...uniqueCollections, action.payload]
      }
    }
    case REMOVE_COLLECTION:
      return {
        ...state,
        collection: action.payload,
        collections: state.collections.filter(
          (item) => item.collectionId !== action.payload.collectionId
        )
      }
    default:
      return state
  }
}
