/* eslint-disable no-duplicate-case */
import { SET_PROFILE } from '../types/profileConstants'

const initialState = {
  profile: {}
}

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload
      }
    default:
      return state
  }
}
