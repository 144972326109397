import { IThemeInterface } from './types'

export const mgInvestments: IThemeInterface = {
  brand: {
    rgb: {
      primary: '#055a60',
      secondary: '#eb5c37',
      warning: '#ffb81c',
      info: '#65b4e5',
      success: '#7ebd5f',
      danger: '#e98276',
      dark: '#2f2f2f',
      light: '#ffffff'
    }
  },
  colors: {
    violet: '#7b76b6',
    lightpink: '#e58ebb',
    petrol: '#0097a9',
    yellow: '#ffb81c',
    coral: '#eb5c37',
    grey: '#5b656e',
    lightcoral: '#e98276',
    jade: '#17b0ad'
  },
  font: {
    SMALLEST: '0.4rem',
    SMALLER: '0.6rem',
    SMALL: '0.8rem',
    MEDIUM: '1rem',
    LARGE: '1.2rem',
    LARGER: '1.4rem',
    LARGEST: '1.6rem'
  },
  components: {
    highlighter: '#F59E0B'
  }
}
