/* eslint-disable no-duplicate-case */
import {
  ADD_APP_DATA,
  ADD_APP_APPEARANCE,
  ADD_APP_DETAILS,
  INITIAL_STATE,
  ADD_APP_ONBOARDING_DATA
} from '../types/appOnboardingConstants'
import { v4 as uuid } from 'uuid'

const initialState = {
  appOnboardingData: {
    appData: {
      iconName: 'UR',
      appId: 0,
      draftApplicationId: 0,
      appName: '',
      appUrl: '',
      selectedCategories: undefined,
      selectedTags: undefined,
      shortDescription: '',
      longDescription: '',
      features: [{ id: uuid(), feature: '' }]
    },
    appAppearance: {
      icon: [],
      screenshots: []
    },
    appDetails: {
      versionId: 0,
      versionNumber: '',
      ticketNumber: '-',
      versionDescription: '',
      team: '',
      businessContacts: [
        {
          id: uuid(),
          email: '',
          name: ''
        }
      ],
      applicationUserGuides: [],
      hasUserGuide: false,
      userGuideType: 'url',
      userGuideUrl: '',
      userGuideDoc: {},
      contacts: [
        {
          id: uuid(),
          email: '',
          name: ''
        }
      ],
      hideArcRoles: false,
      haveArcRoles: true,
      arcRoles: [
        {
          id: uuid(),
          role: '',
          group: '',
          description: ''
        }
      ],
      onlyAdminCanView: false
    }
  }
}

export default function appOnboardingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APP_DATA:
      return {
        ...state,
        appOnboardingData: {
          ...state.appOnboardingData,
          appData: action.payload
        }
      }
    case ADD_APP_APPEARANCE:
      return {
        ...state,
        appOnboardingData: {
          ...state.appOnboardingData,
          appAppearance: action.payload
        }
      }
    case ADD_APP_DETAILS:
      return {
        ...state,
        appOnboardingData: {
          ...state.appOnboardingData,
          appDetails: action.payload
        }
      }
    case ADD_APP_ONBOARDING_DATA:
      return {
        ...state,
        appOnboardingData: action.payload
      }
    case INITIAL_STATE:
      return initialState
    default:
      return state
  }
}
