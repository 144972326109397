export const localConfig = {
  REACT_APP_AZURE_CLIENT_ID: '4f021978-61e5-460b-be42-01b34e8edc3d',
  REACT_APP_API_URL: 'https://as-mg-dev1-t1-weu-invdesktop-api-01.azurewebsites.net',
  REACT_APP_AZURE_REDIRECT_URL: 'http://localhost:3000',
  REACT_APP_AZURE_AUTHORITY:
    'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66',
  REACT_APP_AZURE_SCOPE: 'api://2a8c1f76-b39b-4dd2-8200-f4eb40a751b1/user_impersonation',
  REACT_FEATURE_FLAG_SDK_KEY: 'a1vbCILaeEaBRRcUsTLDUA/pHdCE1xjj06IYBJlg6xraQ',
  REACT_ALI_API_KEY: '8f821741-8c3d-4557-89b0-33656e92b68e'
}

export const getConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    return localConfig
  } else {
    return window.config
  }
}
