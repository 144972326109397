export const widgetSettings = { widgetWidth: '300', widgetHeight: '300' }
export const notificationDelayTimer = 1500
export const sizeInBytes = 1048576
export const loginScreenTitle = 'Centrali'
export const loginScreenSubTitle = "Let's get started by setting up your account"
export const accountInfo = 'Account Information'
export const accountInfoAccDetails = 'Enter your account details'
export const personalInfoDetails =
  'Please answer the question below to get the best experience on Centrali'
export const staticAssetsPath = 'https://samgnonprodt1weuinvd01.blob.core.windows.net/assets'
export const starterKitLink =
  'https://investmenttransformation.visualstudio.com/Analytics%20Platform/_git/AP.Analytics.Portal.StarterKit'
export const docusaurus = 'https://centralios.mandg.com/'
export const dsm = 'https://as-mg-dev1-t1-weu-invdesktop-dsm-01.azurewebsites.net/dashboard/home'
export const contactEmail = 'MandGAnalyticsmailbox@MandG.co.uk'
export const mgsite = 'https://savingsandinvestments.sharepoint.com/sites/mandg'
export const cSharpKit =
  'https://dev.azure.com/investmenttransformation/Analytics%20Platform/_git/Autocode.NET'
export const centraliUserGuides =
  'https://confluence-mandg.valiantys.net/display/InvAn/Centrali+User+Guide'
export const releaseNotes = 'https://centralicms.mandg.com/centrali_release'
export const excelFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
export const spotfireServerRootUrl = 'https://spotfire.mandg.co.uk/spotfire/wp/'

export const APP = {
  NAME: 'App name',
  SHORT_DESCRIPTION: 'Plan Your Day perfectly, Notes taking, Reminders'
}

export const MANDG_GDPR_REVIEWS =
  'By submitting this review you are aware and agree to your review and name being shared with M&G employees'

export const ARC_ROLE_REQUEST_TITLE = `Access permission requested for `

export const WORKSPACE_APPLICATION_HEADLINES = {
  RECENTLY_OPEN_HEADLINE: 'You have recently opened these apps',
  FAVOURITE_APPS_HEADLINE: 'Favourites',
  MY_APPS_HEADLINE: 'My apps',
  COLLECTIONS_HEADLINE: 'My collections'
}

export const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'remove',
    'history'
  ]
}

export const editorConfig = {
  ADD_ATTR: ['target']
}

export const ItemTypes = {
  PROMPT: 'prompt'
}
