import React, { useEffect, useState } from 'react'
import { staticAssetsPath } from '../../configs/constants'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: rgba(5, 90, 96, 0.1);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SplashScreen: React.FC = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false)
    }, 3500)

    return () => clearTimeout(timeout)
  }, [])

  return show ? (
    <Wrapper>
      <img
        className="vh-100"
        src={`${staticAssetsPath}/images/logo/Centrali-Loader.gif`}
        alt="Loading"
      />
    </Wrapper>
  ) : null
}
