import {
  ADD_WORKSPACE,
  REMOVE_WORKSPACE,
  UPDATE_WORKSPACE,
  SET_ACTIVE_WORKSPACE,
  REARRANGE_WORKSPACE
} from '../types/workspaceConstants'

const initialState = {
  workspaces: [],
  activeWorkspace: null
}

export default function workspaceReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WORKSPACE: {
      return {
        ...state,
        workspaces: [...state.workspaces, action.payload],
        activeWorkspace: action.payload.id
      }
    }
    case UPDATE_WORKSPACE: {
      const updatedWorkspaces = state.workspaces.map((workspace) => {
        return workspace.id === action.payload.id ? action.payload : workspace
      })
      return {
        ...state,
        workspaces: updatedWorkspaces
      }
    }
    case REMOVE_WORKSPACE: {
      const updatedWorkspace = state.workspaces.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        workspaces: updatedWorkspace,
        ...(action.payload.id === state.activeWorkspace
          ? {
              activeWorkspace: updatedWorkspace.length
                ? updatedWorkspace[updatedWorkspace.length - 1].id
                : null
            }
          : {})
      }
    }
    case SET_ACTIVE_WORKSPACE: {
      return {
        ...state,
        activeWorkspace: action.payload
      }
    }
    case REARRANGE_WORKSPACE: {
      return {
        ...state,
        workspaces: action.payload
      }
    }
    default:
      return state
  }
}
