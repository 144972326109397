import { combineReducers } from 'redux'
import layoutReducer from './layoutReducer'
import navbarReducer from './navbarReducer'
import appsReducer from './appsReducer'
import iframeReducer from './iframeReducer'
import recentlyAppsReducer from './recentlyAppsReducer'
import favoriteAppsReducer from './favoriteAppsReducer'
import profileReducer from './profileReducer'
import userContextReducer from './userContextReducer'
import notificationContextReducer from './notificationContextReducer'
import appOnboardingReducer from './appOnboardingReducer'
import searchAppsReducer from './searchAppsReducer'
import widgetsReducer from './widgetsReducer'
import reviewsReducer from './reviewsReducer'
import collectionsReducer from './collectionsReducer'
import customCollectionsReducer from './customCollectionsReducer'
import collectionOnboardingReducer from './collectionOnboardingReducer'
import chatbotReducer from './chatbotReducer'
import workspaceReducer from './workspaceReducer'
import smartFlowReducer from './smartFlowReducer'

const rootReducer = combineReducers({
  layoutReducer,
  navbarReducer,
  appsReducer,
  iframeReducer,
  recentlyAppsReducer,
  favoriteAppsReducer,
  userContextReducer,
  notificationContextReducer,
  profileReducer,
  appOnboardingReducer,
  searchAppsReducer,
  widgetsReducer,
  reviewsReducer,
  collectionsReducer,
  collectionOnboardingReducer,
  chatbotReducer,
  customCollectionsReducer,
  workspaceReducer,
  smartFlowReducer
})

export default rootReducer
