/* eslint-disable no-duplicate-case */
import {
  ADD_RECENTLY_APP,
  REMOVE_RECENTLY_APP,
  INIT_RECENTLY_APP
} from '../types/recentlyAppConstants'

const initialState = {
  recentlyApps: [],
  recentlyApp: {}
}

export default function recentlyAppsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_RECENTLY_APP:
      return {
        ...state,
        recentlyApps: action.payload
      }
    case ADD_RECENTLY_APP: {
      const uniqueApps = state.recentlyApps.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        recentlyApp: action.payload,
        recentlyApps: [...uniqueApps, action.payload]
      }
    }
    case REMOVE_RECENTLY_APP:
      return {
        ...state,
        recentlyApp: action.payload,
        recentlyApps: state.recentlyApps.filter((item) => item.id !== action.payload.id)
      }
    default:
      return state
  }
}
