/* eslint-disable no-duplicate-case */
import { ADD_COLLECTION_DATA, INITIAL_STATE } from '../types/collectionOnboardingConstants'
import { v4 as uuid } from 'uuid'

const initialState = {
  collectionOnboardingData: {
    collectionData: {
      collectionName: '',
      selectedApps: undefined,
      shortDescription: ''
    }
  }
}

export default function collectionOnboardingReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COLLECTION_DATA:
      return {
        ...state,
        collectionOnboardingData: {
          ...state.collectionOnboardingData,
          collectionData: action.payload
        }
      }
    case INITIAL_STATE:
      return initialState
    default:
      return state
  }
}
