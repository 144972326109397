import {
  SET_CHAT_MODE,
  SET_MODEL_ENGINE,
  SET_END_DOCUMENT_MODE,
  SET_SHOW_FILE_VIEW,
  LAUNCH_ALI,
  SET_ALI_SEGMENT,
  SET_ALI_OPEN_SIDEBAR,
  SET_ALI_IS_MODAL_OPEN,
  SET_BAR_MODE,
  SET_ALI_CHAT_THEME,
  SET_ALI_DRAWER_WIDTH,
  SET_FULL_SCREEN,
  SET_NEW_CONVERSATION,
  SET_WEB_SEARCH,
  SET_SHOW_DATATOPIC_MODAL,
  SET_SMART_FLOW
} from '../actions/chatbotActions'

const initialState = {
  chatMode: 'chat',
  showFileView: false,
  barMode: {
    previousMode: null,
    active: null,
    files: [],
    source: null
  },
  isFullScreen: false,
  canLaunchAli: false,
  isInAliSegment: false,
  openSidebar: false,
  isModalOpen: false,
  chatTheme: 'primary',
  modelEngine: 'chat-gpt-35-16k',
  aliDrawerWidth: '',
  isNewConversation: true,
  enableWebSearch: false,
  showDataTopicModal: false,
  showSmartFlow: false
}

export default function chatbotReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALI_DRAWER_WIDTH: {
      return {
        ...state,
        aliDrawerWidth: action.payload
      }
    }
    case SET_CHAT_MODE: {
      return {
        ...state,
        chatMode: action.payload
      }
    }
    case SET_MODEL_ENGINE: {
      return {
        ...state,
        modelEngine: action.payload
      }
    }
    case SET_BAR_MODE: {
      return {
        ...state,
        barMode: {
          ...state.barMode,
          active: action.payload.active,
          files: action.payload.files,
          previousMode: action.payload.previousMode,
          source: action.payload.source
        }
      }
    }
    case SET_SHOW_FILE_VIEW: {
      return {
        ...state,
        showFileView: action.payload
      }
    }
    case LAUNCH_ALI:
      return {
        ...state,
        canLaunchAli: action.payload.canLaunchAli
      }
    case SET_ALI_SEGMENT:
      return {
        ...state,
        isInAliSegment: action.payload.isInAliSegment
      }
    case SET_ALI_OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: action.payload.openSidebar
      }
    case SET_ALI_IS_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen
      }
    case SET_ALI_CHAT_THEME:
      return {
        ...state,
        chatTheme: action.payload
      }
    case SET_FULL_SCREEN:
      return {
        ...state,
        isFullScreen: action.payload
      }
    case SET_NEW_CONVERSATION:
      return {
        ...state,
        isNewConversation: action.payload
      }
    case SET_WEB_SEARCH:
      return {
        ...state,
        enableWebSearch: action.payload
      }
    case SET_SHOW_DATATOPIC_MODAL:
      return {
        ...state,
        showDataTopicModal: action.payload
      }
    case SET_SMART_FLOW:
      return {
        ...state,
        showSmartFlow: action.payload
      }
    default:
      return state
  }
}
