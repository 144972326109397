/* eslint-disable no-duplicate-case */
import { INIT_REVIEWS, ADD_REVIEW } from '../types/reviewConstants'

const initialState = {
  reviews: [],
  review: {}
}

export default function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_REVIEWS:
      return {
        ...state,
        reviews: [...action.payload]
      }
    case ADD_REVIEW:
      return {
        ...state,
        review: action.payload,
        reviews: [action.payload, ...state.reviews]
      }
    default:
      return state
  }
}
