/* eslint-disable no-case-declarations */
/* eslint-disable no-duplicate-case */
import {
  ADD_APP_TO_IFRAME,
  REMOVE_APP_FROM_IFRAME,
  SELECTED_APP_TO_IFRAME,
  UPDATE_APP_FROM_IFRAME,
  UPDATE_IFRAME_APP_URL
} from '../types/iframeConstants'

const initialState = {
  iframeApps: [],
  iframeApp: {},
  iframeAppSelected: {}
}

export default function iframeReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APP_TO_IFRAME: {
      const uniqueApps = state.iframeApps.filter((item) => item.id !== action.payload.id)
      return {
        ...state,
        iframeApp: action.payload,
        iframeApps: [...uniqueApps, action.payload]
      }
    }
    case SELECTED_APP_TO_IFRAME:
      return {
        ...state,
        iframeApp: action.payload
      }
    case REMOVE_APP_FROM_IFRAME:
      const nextIdx = state.iframeApps.findIndex((item) => item.id === action.payload.id)
      const updatedApps = state.iframeApps.filter((item) => item.id !== action.payload.id)
      const currentApp = updatedApps.filter((item) => item.id === state.iframeApp?.id)
      return {
        ...state,
        ...(currentApp.length === 0
          ? {
              iframeApp:
                updatedApps.length === nextIdx
                  ? updatedApps[updatedApps.length - 1]
                  : updatedApps[nextIdx]
            }
          : {}),
        iframeApps: updatedApps
      }
    case UPDATE_APP_FROM_IFRAME:
      return {
        ...state,
        iframeApps: action.payload
      }
    case UPDATE_IFRAME_APP_URL:
      return {
        ...state,
        iframeApp: action.payload,
        iframeApps: state.iframeApps.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          } else {
            return item
          }
        })
      }
    default:
      return state
  }
}
