/* eslint-disable no-duplicate-case */
import {
  ADD_NEW_NOTIFICATION_TO_CONTEXT,
  NOTIFICATION_CONTEXT_SET_NEW_COUNT,
  SET_NOTIFICATION_CONTEXT,
  SET_SCHEDULE_NOTIFICATIONS,
  ADD_NEW_SCHEDULE_NOTIFICATION
} from '../types/notificationConstants'

const initialState = {
  notificationContext: {
    notifications: []
  },
  scheduleNotificationContext: {
    notifications: [],
    isBannerVisible: false
  }
}

export default function notificationContextReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_CONTEXT:
      return {
        ...state,
        notificationContext: action.payload
      }
    case ADD_NEW_NOTIFICATION_TO_CONTEXT: {
      const notifications = [action.payload, ...state.notificationContext.notifications]
      const newCount = state.notificationContext.newCount + 1
      return {
        ...state,
        notificationContext: {
          notifications,
          newCount
        }
      }
    }
    case SET_SCHEDULE_NOTIFICATIONS:
      return {
        ...state,
        scheduleNotificationContext: action.payload
      }
    case ADD_NEW_SCHEDULE_NOTIFICATION: {
      const notifications = [action.payload, ...state.scheduleNotificationContext.notifications]
      return {
        ...state,
        scheduleNotificationContext: {
          notifications
        }
      }
    }
    case NOTIFICATION_CONTEXT_SET_NEW_COUNT: {
      return {
        ...state,
        notificationContext: {
          ...state.notificationContext,
          newCount: action.payload
        }
      }
    }
    default:
      return state
  }
}
